import React, { FC } from 'react';

import { IProductCardTitle } from '@shared/types';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';

const ProductCardTitle: FC<IProductCardTitle> = ({ titleToShow }) => (
  <DangerouslySetInnerHtml element="p" className="product-card__title" html={titleToShow} />
);

export default ProductCardTitle;
